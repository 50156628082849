@import '../../../scss/abstractions.scss';

.dash-component {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: $white;
  box-shadow: $bs-dash-component;
  margin-top: 2rem;
  max-height: 3.8rem;
  overflow: hidden;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);

  @media screen and (min-width: $bp-md) {
    max-height: 4.5rem;
  }

  &.toggle-dash-component {
    max-height: 500rem;
    transition: all 5000ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  &--width-full {
    flex: 1;
  }

  &--width-half {
    width: initial;
    @media screen and (min-width: $bp-md) {
      width: 48.7%;
    }
  }

  &__collapse-button {
    position: absolute;
    left: 10px;
    top: 10px;
    cursor: pointer;

    @media screen and (min-width: $bp-md) {
      top: 10px;
      left: 20px;
    }

    & rect:nth-of-type(2),
    & rect:nth-of-type(3) {
      transform-origin: center;
      transition: all 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }

    & rect:nth-of-type(2) {
      transform: rotate(90deg);
    }

    & rect:nth-of-type(3) {
      transform: rotate(0);
    }

    &.toggle-dash-component rect:nth-of-type(2) {
      transform: rotate(180deg) scale(0);
    }

    &.toggle-dash-component rect:nth-of-type(3) {
      transform: rotate(270deg);
    }
  }

  &__header {
    display: flex;
    background-color: $black;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: relative;
    z-index: 0;

    & .MuiSelect-icon {
      top: calc(50% - 7px);
    }

    & svg {
      height: 1.6rem;
      @media screen and (min-width: $bp-md) {
        height: 2.2rem;
      }
    }

    &--input-header {
      padding: 1rem 0 5rem 0;

      @media screen and (min-width: $bp-sm) {
        padding: 1rem 0;
      }
    }
    label {
      display: none;

      &.Mui-focused {
        color: $off-white;
      }

      &.MuiFormLabel-filled {
        color: $off-white;
      }
    }

    input:-internal-autofill-selected {
      background-color: transparent;
    }

    .MuiInput-underline {
      &:hover:not(:disabled):not(:focus):before {
        border-bottom: 1px solid $off-white;
      }
    }

    .MuiInput-input {
      color: $off-white;
      font-size: 1.2rem;
      line-height: 170%;
      width: 100px;
      @media screen and (min-width: $bp-sm) {
        width: 90px;
      }
      @media screen and (min-width: $bp-md) {
        width: 100px;
      }
      transition: width 300ms cubic-bezier(0.23, 1, 0.32, 1);
      &:focus {
        width: 150px;
        @media screen and (min-width: $bp-sm) {
          width: 125px;
        }
      }
    }

    .MuiInput-underline:after {
      border-bottom: 1px solid $off-white;
    }

    .MuiInput-underline:before {
      border-bottom: 1px solid $off-white;
    }

    &__form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .MuiFormControl-root {
      position: absolute;
      z-index: 2;
      right: 50%;
      bottom: 10px;
      transform: translateX(50%);
      @media screen and (min-width: $bp-sm) {
        right: 40px;
        bottom: 10px;
        transform: translateX(0);
      }
    }

    .MuiInputAdornment-root {
      color: $off-white;
      position: relative;
      top: 2px;
    }

    .MuiSvgIcon-root {
      font-size: 2rem;
    }
  }

  //SELECT INPUT
  &__header-select {
    & .MuiInput-input {
      width: 100px;
      text-align: center;
      text-transform: uppercase;
      position: relative;
      top: 5px;

      &:focus {
        width: 100px;
      }
    }

    & .MuiSvgIcon-root path {
      fill: $off-white;
    }
  }

  &__title {
    font-size: 1.6rem;
    font-weight: 700;
    color: $off-white;
    text-transform: uppercase;
    margin-left: 0.5rem;

    @media screen and (min-width: $bp-md) {
      font-size: 2.2rem;
      margin-left: 1rem;
    }
  }
}
