// COLORS
$white: #fff;
$off-white: #efefef;
$grey-light: #dedede;
$grey-standard: #979797;
$grey-dark: #646464;
$grey-black: #3c4043;
$black: #121212;
$yellow: #f3ad61;
$green: #2cd67d;
$blue: #56ccf2;
$red: #cf4664;
$violet: #8c9eff;

// TYPOGRAPHY
$montserrat: 'Montserrat', Arial, Helvetica, sans-serif;

// BOXSHADOW
$bs-dash-component: 0 1px 4px rgba($black, 0.2);
$bs-select-menu: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
  0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);

// BREAK POINTS
$bp-xs: 321px;
$bp-sm: 600px;
$bp-md-sm: 900px;
$bp-md: 1280px;
$bp-lg: 1550px;

// TRANSITIONS
$txn-slow: 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

// ANIMATIONS
@keyframes fade-in-content {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);

    opacity: 1;
  }
}

@keyframes fade-in-dash-page {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slide-in-left {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-30px);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}

// MIXINS
%dash-component-form {
  padding: 5vh 2vw;

  @media screen and (min-width: $bp-sm) {
    padding: 5vh 3vw;
  }

  @media screen and (min-width: $bp-md-sm) {
    padding: 5vh 2vw;
  }

  @media screen and (min-width: $bp-md) {
    padding: 5vh 5vw;
  }
}

%dash-component-trade-profile {
  padding: 5vh 2vw;
  @media screen and (min-width: $bp-xs) {
    padding: 5vh 5vw;
  }
  @media screen and (min-width: $bp-sm) {
    padding: 7vh 5vw;
  }
}

%custom-line-chart-labels {
  & svg > g g:nth-child(2) line,
  & svg > g g:nth-child(3) line {
    display: none;
  }

  & svg > g g:nth-child(2) g text,
  & svg > g g:nth-child(3) g text {
    font-size: 8px !important;
    font-family: $montserrat !important;
    font-weight: 700;
    letter-spacing: 0.8px;
    fill: $grey-dark !important;
  }
}

%fade-in-dash-page {
  opacity: 1;
  // animation: fade-in-dash-page forwards 400ms
  //   cubic-bezier(0.39, 0.575, 0.565, 1);
}

%dash-page-min-height {
  min-height: 80vh;
}

%slide-in-left-fast {
  opacity: 0;
  visibility: hidden;
  transform: translateX(-30px);
  animation: slide-in-left forwards 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
