@import '../../../scss/abstractions.scss';

.message-modal {
  @extend %dash-component-form;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
  position: fixed;
  z-index: 2000;
  width: initial;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  bottom: initial;
  border-radius: 8px;
  overflow-y: auto;
  min-width: 300px;
  @media screen and (min-width: $bp-sm) {
    align-items: flex-start;
  }

  & input[type='submit'] {
    align-self: flex-start;
  }

  & .MuiFormControlLabel-root {
    margin-right: 3rem;
    &.Mui-disabled {
      text-decoration: line-through;
    }
  }

  &__form {
    display: flex;
    align-items: center;
    flex-direction: column;
    @media screen and (min-width: $bp-sm) {
      align-items: flex-start;
    }
  }

  & .custom-mui-form .MuiFormControl-root {
    max-width: 200px;

    @media screen and (min-width: $bp-sm) {
      max-width: 100%;
    }
  }

  & .MuiInputBase-inputMultiline {
    line-height: 140%;
  }

  &__avatar-group {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 2vh;
  }

  &__avatar-status {
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    background-color: $green;
    position: absolute;
    top: 0px;
    left: 26px;
    z-index: 5;
    box-shadow: 0 1px 3px rgba($black, 0.5);
  }

  &__avatar {
    margin-right: 1rem;
  }

  &__checkbox-group {
    margin: 3vh 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @media screen and (min-width: $bp-sm) {
      flex-direction: row;
    }

    & .MuiTypography-body1 {
      font-size: 1.6rem;
      font-family: $montserrat;
    }
  }

  & .MuiIconButton-colorSecondary {
    &:hover {
      background-color: rgba($yellow, 0.2);
    }

    &.Mui-checked {
      color: $yellow;
    }
  }
}
