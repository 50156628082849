@import '../../../scss/abstractions.scss';

.blacklist-form {
  @extend %dash-component-form;

  &__chip-display {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0 3rem;
  }

  &__chip {
    &.MuiChip-root {
      font-size: 1.2rem;
      margin: 0.5rem 0.2rem;
      background-color: rgba($black, 0.6);
      display: flex;
      color: $off-white;
      & path {
        fill: $off-white;
      }
      @media screen and (min-width: $bp-sm) {
        margin: 0.5rem 0.6rem;
      }
      @media screen and (min-width: $bp-md) {
        margin: 0.5rem 0.2rem;
      }
    }

    &.MuiChip-clickable:hover,
    &.MuiChip-clickable:focus {
      background-color: rgba($black, 0.4);
    }
  }

  input[type='submit'] {
    margin: 3vh 0 7vh 0;
  }
}
