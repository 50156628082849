@import '../../scss/abstractions.scss';

.contact-us {
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    max-width: 31rem;

    @media screen and (min-width: $bp-md-sm) {
      max-width: 100%;
    }
  }

  section {
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vh 0;
    width: 100%;
    animation: fade-in-content forwards 300ms 100ms
      cubic-bezier(0.39, 0.575, 0.565, 1);

    @media screen and (min-width: $bp-sm) {
      padding: 7vh 0;
      max-width: 60rem;
    }
    @media screen and (min-width: $bp-md-sm) {
      align-items: flex-start;
      max-width: 80rem;
    }

    @media screen and (min-width: $bp-md) {
      padding: 10vh 0;
      max-width: 120rem;
    }
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: $bp-md-sm) {
      align-items: flex-start;
    }

    // Mui form styles
    label {
      font-size: 1.6rem;
      text-transform: capitalize;
      color: rgba($black, 0.5);

      &.Mui-focused {
        color: $yellow;
      }

      &.MuiFormLabel-filled {
        color: $yellow;
      }
    }

    input:-internal-autofill-selected {
      background-color: transparent;
    }

    .MuiInput-underline {
      &:hover:not(:disabled):not(:focus):before {
        border-color: lighten($black, 50%);
      }
    }

    .MuiInput-input {
      color: $black;
      font-size: 2rem;
      line-height: 170%;
    }

    .MuiInput-underline:after {
      border-color: rgba($black, 0.5);
    }

    .MuiInput-underline:before {
      border-color: rgba($black, 0.5);
    }
  }

  &__form-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 3rem;
    margin: 3vh 0;
    @media screen and (min-width: $bp-md-sm) {
      flex-direction: row;
      padding: 0;
      margin: 5vh 0;
    }
    &--top {
      margin-top: 5vh;

      & div:first-of-type {
        margin-right: 0;
        margin-bottom: 3vh;

        @media screen and (min-width: $bp-md-sm) {
          margin-right: 5vw;
          margin-bottom: 0;
        }
      }
      & div:nth-of-type(2) {
        margin-right: 0;
        margin-top: 3vh;
        @media screen and (min-width: $bp-md-sm) {
          margin-left: 5vw;
          margin-top: 0;
        }
      }
    }

    &--bottom {
      margin-bottom: 7vh;
    }
  }

  &__discord {
    margin-bottom: 10vh;
    margin-top: 5vh;

    text-align: center;
    @media screen and (min-width: $bp-sm) {
      margin-top: 0;
    }

    @media screen and (min-width: $bp-md-sm) {
      text-align: left;
    }

    h3 {
      margin-bottom: 5vh;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-right: 1rem;
      }
    }
  }
}
