@import '../../../../scss/abstractions.scss';

.activity-table-row {
  display: flex;
  flex-direction: column;
  padding: 3rem 2vw 1rem 2vw;
  justify-content: flex-start;
  align-items: center;
  background-color: $white;

  @media screen and (min-width: $bp-sm) {
    flex-direction: row;
    padding: 4rem 2vw 4rem 5vw;
  }

  &--dark {
    background-color: lighten($grey-light, 6%);
  }

  &__mobile-label {
    display: inline-block;
    margin-bottom: 5px;
    @media screen and (min-width: $bp-sm) {
      display: none;
    }
  }

  &__item {
    width: 100%;
    font-size: 1.2rem;
    font-weight: 500;
    color: $grey-dark;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    flex-direction: column;
    word-break: break-all;
    margin-bottom: 4rem;

    @media screen and (min-width: $bp-sm) {
      width: 26.5vw;
      justify-content: flex-start;
      flex-direction: row;
      margin-bottom: 0;
    }

    @media screen and (min-width: $bp-md) {
      width: 30vw;
      font-size: 1.4rem;
    }
    &--username {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 1.4;
    }
  }

  &__avatar {
    transform: translateX(0);
    @media screen and (min-width: $bp-sm) {
      transform: translateX(-10px);
    }
  }

  & .MuiAvatar-root {
    width: 8rem;
    height: 8rem;
    margin-bottom: 5px;

    @media screen and (min-width: $bp-sm) {
      margin-bottom: 0;
      width: 4rem;
      height: 4rem;
    }
  }

  &__avatar-status {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: $green;
    position: absolute;
    top: 2px;
    right: 4px;
    z-index: 5;
    box-shadow: 0 1px 3px rgba($black, 0.5);
    @media screen and (min-width: $bp-sm) {
      top: -1px;
      right: 10px;
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__avatar-group {
    position: relative;
  }

  &__button-container {
    display: flex;
    justify-content: center;
    width: 20vw;
    @media screen and (min-width: $bp-sm) {
      width: 5vw;
    }
    svg {
      height: 2rem;
      margin: 0 2rem;
      cursor: pointer;
      @media screen and (min-width: $bp-sm) {
        margin-right: 2rem;
      }
    }
  }
}
