@import '../../../scss/abstractions.scss';

.signal-modal {
  background-color: rgba($black, 0.98);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: scroll;
  z-index: 2000;
  padding: 0;
  border-radius: 0;
  width: 100vw;
  padding: 6vh 0;
  opacity: 0;
  animation: fade-in 100ms forwards cubic-bezier(0.47, 0, 0.745, 0.715);
  @media screen and (min-width: $bp-sm) {
    padding: 0;
    width: initial;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    bottom: initial;
    border-radius: 8px;
    overflow-y: auto;
  }
  @media screen and (min-width: $bp-md-sm) {
    top: 50vh;
  }

  &__close-btn {
    width: 2rem;
    height: auto;
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;

    @media screen and (min-width: $bp-sm) {
      width: 2.5rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__row {
    display: flex;
    align-items: center;
    flex-direction: column;
    @media screen and (min-width: $bp-sm) {
      flex-direction: row;
    }
  }

  &__cancel-signal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vh 0 3.5vh 0;

    span {
      font-size: 1.4rem;
      color: $red;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 1vh;
    }

    svg {
      width: 3rem;
      height: auto;
      cursor: pointer;
    }
  }
}

.data-group {
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3vh 4vw;
  width: 100%;
  max-width: 80vw;
  word-break: break-word;
  text-align: center;

  @media screen and (min-width: $bp-sm) {
    align-items: flex-start;
    margin: 4vh 4vw;
    width: 11.4rem;
    word-break: normal;
    text-align: left;
  }

  @media screen and (min-width: $bp-md) {
    margin: 5vh 3vw;
    width: 13rem;
  }

  &__label {
    font-size: 1.4rem;
    font-weight: 500;
    &--blue {
      color: $blue;
    }
    &--yellow {
      color: $yellow;
    }
    &--violet {
      color: $violet;
    }
    &--green {
      color: $green;
    }
    &--red {
      color: $red;
    }
  }

  &__data {
    color: $off-white;
    font-size: 2rem;
    font-weight: 500;
    word-wrap: break-word;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
