@import '../../../../scss/abstractions.scss';

.signal-table-row {
  position: relative;
  z-index: 2;
  padding: 3rem 0 3rem 10vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 50ms cubic-bezier(0.47, 0, 0.745, 0.715);
  @media screen and (min-width: $bp-sm) {
    padding: 4rem 2vw 4rem 5vw;
  }
  &:hover,
  &:focus {
    z-index: 5;
    border-color: $green;
    // transform: scale(1.05);
    box-shadow: 0 0 10px rgba($green, 0.8);
    outline: none;

    transition: all 100ms cubic-bezier(0.47, 0, 0.745, 0.715);
  }

  &--dark {
    background-color: lighten($grey-light, 6%);
  }
  &__data {
    width: 30%;
    font-size: 1.6rem;
    font-weight: 500;
    color: $grey-dark;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    @media screen and (min-width: $bp-sm) {
      width: 10%;
    }

    &--amount {
      padding-left: 4vw;
      @media screen and (min-width: $bp-sm) {
        padding: 0;
      }
    }

    &.remove-at-sm {
      display: none;

      @media screen and (min-width: $bp-sm) {
        display: block;
      }
    }

    &--symbol {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      & span {
        color: $yellow;
        font-size: 1.2rem;
        font-weight: 700;
        margin-bottom: 2px;
      }
    }
  }
}
