@import '../../../scss/abstractions.scss';

.menu-icon {
  display: block;
  cursor: pointer;
  z-index: 2000;
  width: 5.4rem;
  height: auto;

  &__top-bar {
    transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
    transform-origin: center center;

    &.menu-open {
      transform: rotate(405deg) translateY(10px) translateX(-2px);
    }
  }

  &__mid-bar {
    transform-origin: left center;
    transition: all 200ms cubic-bezier(0.39, 0.575, 0.565, 1);

    &.menu-open {
      transition: all 250ms cubic-bezier(0.39, 0.575, 0.565, 1);
      transform: scaleX(0);
    }
  }

  &__bot-bar {
    transform-origin: center center;
    transition: all 200ms cubic-bezier(0.19, 1, 0.22, 1);

    &.menu-open {
      transform: rotate(315deg) translateY(-11px) translateX(-2px);
    }
  }

  @media screen and (min-width: $bp-md-sm) {
    display: none;
  }
}
