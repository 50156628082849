@import '../../scss/abstractions.scss';

.btn {
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 500;
  border: 1px solid transparent;
  cursor: pointer;

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }

  &--header {
    padding: 8px 14px;
    font-size: 1.2rem;
    display: flex;
    width: 9rem;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.4px;
  }

  &--body {
    padding: 15px 20px;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
  }

  &--solid {
    background-color: $yellow;
    color: $black;
    &:hover,
    &:focus {
      background-color: darken($yellow, 10%);

      &.disabled {
        background-color: $yellow;
      }
    }
    transition: background-color 100ms cubic-bezier(0.77, 0, 0.175, 1);
  }

  &--outline {
    background-color: transparent;
    color: $off-white;
    border-color: $off-white;
    &:hover,
    &:focus {
      background-color: rgba($off-white, 0.1);
    }
    transition: all 100ms cubic-bezier(0.77, 0, 0.175, 1);
  }
}
