@import '../../../scss/abstractions.scss';

.lower-cta {
  margin: 10vh 0;
  padding: 10vh 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
    url('../../../static/img/bitcoin-falling.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  opacity: 0;
  transform: translateX(-50%);
  transition: transform 500ms cubic-bezier(0.19, 1, 0.22, 1);

  @media screen and (min-width: $bp-sm) {
    padding: 15vh 10px;
  }

  &.reveal {
    opacity: 1;
    transform: translateX(0);

    & h2,
    & a,
    & p {
      opacity: 1;
      transform: translateX(0);
    }
  }

  & h2,
  & a,
  & p {
    transition: all 700ms 400ms cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    transform: translateX(-30px);
  }

  &__description {
    color: $off-white;
    font-size: 1.6rem;
    line-height: 170%;
    letter-spacing: 0.5px;
    max-width: 80rem;
    margin: 4vh 0;
    @media screen and (min-width: $bp-sm) {
      font-size: 1.6rem;
    }
  }
}
