@import '../../../../../scss/abstractions.scss';

.display-box {
  min-width: 20rem;
  margin: 0 2rem;
  box-shadow: 1px 2px 8px rgba($black, 0.5);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  transition: all 100ms cubic-bezier(0.39, 0.575, 0.565, 1);
  &__symbol {
    font-size: 1.2rem;
    font-weight: 700;
    color: $grey-dark;
  }

  &__icon {
    margin: 1.5rem 0;
  }

  &__price {
    font-size: 1.7rem;
    font-weight: 700;
  }

  &__date-container {
    display: flex;
    flex-direction: row;
    margin: 5px 0 1rem 0;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
  &__date {
    font-size: 1.2rem;
    color: $grey-dark;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  &__time {
    font-size: 1.2rem;
    color: $grey-dark;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
}
