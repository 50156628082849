@import '../../../scss/abstractions.scss';

.dashboard-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 32rem;
  background-color: $white;
  padding: 5rem 0 0 0;
  box-shadow: 0 1px 4px rgba($black, 0.2);
  transform: translateX(-100%);
  z-index: 500;
  transition: all 500ms cubic-bezier(0.23, 1, 0.32, 1);
  @media screen and (min-width: $bp-xs) {
    padding: 8rem 0 0 0;
  }

  @media screen and (min-width: $bp-sm) {
    width: 35rem;
  }

  @media screen and (min-width: $bp-md-sm) {
    transform: translateX(0);
    z-index: 50;
    padding: 12rem 0 0 4rem;
  }

  &.position-static {
    & .dashboard-menu__nav-list,
    & .dashboard-menu__avatar-group {
      bottom: 0;

      @media screen and (min-width: $bp-md-sm) {
        bottom: 30vh;
      }

      @media screen and (min-width: $bp-md) {
        bottom: 25vh;
      }

      @media screen and (min-width: $bp-lg) {
        bottom: 20vh;
      }
    }
  }

  &__back-button {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    transform: translateX(-30px);
    opacity: 0;
    transition: all 200ms cubic-bezier(0.39, 0.575, 0.565, 1);
    @media screen and (min-width: $bp-xs) {
      display: none;
    }
    & path {
      fill: $black;
    }

    &.menu-open {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &__avatar-group {
    opacity: 0;
    transform: translateX(-30px) translateZ(0);
    bottom: 0;
    position: relative;
    transition: transform 0ms cubic-bezier(0.23, 1, 0.32, 1),
      opacity 0ms cubic-bezier(0.23, 1, 0.32, 1),
      bottom 500ms cubic-bezier(0.23, 1, 0.32, 1);

    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: $bp-md-sm) {
      opacity: 1;
      transform: translateX(0);
      align-items: flex-start;
    }
  }

  &.menu-open {
    transform: translateX(0);

    & li {
      opacity: 1;
      transform: translateX(0);
      transition: all 500ms 250ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    & .dashboard-menu__avatar-group {
      opacity: 1;
      transform: translateX(0);
      transition: all 500ms 200ms cubic-bezier(0.23, 1, 0.32, 1);
    }
  }

  & .MuiAvatar-root {
    width: 7rem;
    height: 7rem;
    margin-bottom: 1rem;
  }
  &__username {
    font-weight: 700;
    color: $yellow;
    font-size: 1.6rem;
    text-transform: uppercase;
  }

  &__role {
    color: $red;
    font-size: 1.4rem;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin-top: 0.5rem;
    &--emphasized {
      font-weight: 700;
    }
  }

  &__link {
    font-size: 1.8rem;
    font-weight: 500;
    color: $grey-standard;
    display: flex;
    align-items: center;
    line-height: 140%;
    transition: all 100ms cubic-bezier(0.39, 0.575, 0.565, 1);

    &.active {
      transition: font-weight 100ms linear;
      color: $grey-black;
      font-weight: 700;
    }

    &.active path {
      fill: $grey-black;
    }

    &:hover,
    &:focus {
      color: $grey-black;
    }
    &:hover svg > path,
    &:focus svg > path {
      fill: $grey-black;
    }
    &--logout {
      cursor: pointer;
    }
  }

  &__link-icon {
    width: 1.8rem;
    margin-right: 1rem;

    &--height {
      width: auto;
      height: 1.9rem;
    }

    & > path {
      transition: fill 200ms cubic-bezier(0.39, 0.575, 0.565, 1);
    }
  }

  &__nav-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 0;
    position: relative;
    transition: all 500ms cubic-bezier(0.23, 1, 0.32, 1);

    @media screen and (min-width: $bp-md-sm) {
      align-items: flex-start;
    }
  }

  & li {
    margin-bottom: 4vh;
    transform: translateX(-30px);
    opacity: 0;
    transition: all 0ms cubic-bezier(0.23, 1, 0.32, 1);

    @media screen and (min-width: $bp-md-sm) {
      opacity: 1;
      transform: translateX(0);
    }
  }

  & nav {
    margin-top: 4vh;
  }
}
