@import '../../scss/abstractions.scss';

.about-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__content {
    text-align: justify;
    opacity: 0;
    margin-top: 5vh;
    max-width: 120rem;
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    animation: fade-in-content forwards 300ms 100ms
      cubic-bezier(0.39, 0.575, 0.565, 1);

    @media screen and (min-width: $bp-sm) {
      margin: 7vh 4rem 0 4rem;
    }

    @media screen and (min-width: $bp-md) {
      margin-top: 10vh;
    }

    & h2 {
      text-align: center;
      margin-bottom: 3vh;
      @media screen and (min-width: $bp-md) {
        text-align: left;
      }
    }

    & p:last-of-type {
      margin-bottom: 10vh;
    }
  }

  &__feature {
    border-radius: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15rem;

    @media screen and (min-width: $bp-sm) {
      height: 20rem;
    }

    @media screen and (min-width: $bp-md) {
      height: 35rem;
    }
    &--computer-bg {
      background-image: url('../../static/img/typing-computer.png');
      background-position: center bottom;
    }
    &--lock-bg {
      background-image: url('../../static/img/padlock.png');
      background-position: center center;
    }

    &:first-of-type {
      margin-top: 5vh;
    }
    &:last-of-type {
      margin-bottom: 5vh;
    }

    margin-bottom: 2vh;
  }

  &__feature-img {
    object-fit: cover;

    width: 100%;
  }

  &__feature-title {
    color: $off-white;
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: 0.65px;
    text-transform: uppercase;
    text-shadow: 1px 2px 3px rgba($black, 0.8);
    text-align: center;
    max-width: 25rem;
    line-height: 170%;

    @media screen and (min-width: $bp-sm) {
      font-size: 2.8rem;
      max-width: 50rem;
    }

    @media screen and (min-width: $bp-md) {
      font-size: 3.5rem;
      max-width: 80rem;
    }
  }
}
