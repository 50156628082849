@import '../../scss/abstractions.scss';

.footer {
  position: relative;
  z-index: 50;
  background-color: $black;
  display: flex;
  justify-content: center;
  padding: 7vh 10px 7vh 10px;
  color: $off-white;
  @media screen and (min-width: $bp-md) {
    padding: 10vh 0;
  }

  &__layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
    max-width: 1400px;
    @media screen and (min-width: $bp-md) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 900px;
    @media screen and (min-width: $bp-md) {
      align-items: flex-start;
    }
  }

  &__nav-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;

    @media screen and (min-width: $bp-xs) {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
    }

    li {
      margin: 1rem;

      @media screen and (min-width: $bp-md) {
        margin: 0 2vw 0 0;
      }
    }

    a {
      color: $off-white;
      font-size: 1.2rem;
      font-weight: 500;
      text-transform: uppercase;
      @media screen and (min-width: $bp-md) {
        font-size: 1.6rem;
      }
    }
  }

  &__mission-statement {
    font-size: 1.2rem;
    line-height: 170%;
    letter-spacing: 0.5px;
    margin: 3rem 0 4rem 0;
    text-align: center;
    max-width: 600px;
    @media screen and (min-width: $bp-md) {
      text-align: left;
      font-size: 1.8rem;
      max-width: 100%;
    }
  }

  &__copyright {
    font-size: 1rem;
    letter-spacing: 0.5px;
    text-align: left;
    @media screen and (min-width: $bp-md) {
      text-align: center;
      font-size: 1.4rem;
    }
  }
}
