@import './abstractions.scss';

.section-headline {
  font-weight: 500;
  text-transform: capitalize;
  font-size: 2.2rem;
  letter-spacing: 0.6px;
  transition: opacity $txn-slow;
  @media screen and (min-width: $bp-sm) {
    font-size: 2.7rem;
    letter-spacing: 0.8px;
  }
  @media screen and (min-width: $bp-md) {
    font-size: 3.5rem;
  }

  &.hide {
    opacity: 0;
  }

  &.reveal {
    opacity: 1;
  }

  &--white {
    color: $off-white;
  }
}

.sub-section-headline {
  text-transform: capitalize;
  letter-spacing: 0.35px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 140%;
  @media screen and (min-width: $bp-sm) {
    font-size: 2.8rem;
    letter-spacing: 0.6px;
  }
  @media screen and (min-width: $bp-md) {
    font-size: 3.4rem;
    letter-spacing: 0.6px;
  }

  &--white {
    color: $off-white;
  }
}

.body-text {
  font-size: 1.6rem;
  letter-spacing: 0.6px;
  line-height: 170%;
  margin-bottom: 2vh;
  & a {
    color: $yellow;
    text-transform: uppercase;
    font-weight: 700;
  }
}

.step-headline {
  font-size: 1.6rem;
  line-height: 170%;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: capitalize;

  @media screen and (min-width: $bp-md) {
    font-size: 2.2rem;
    letter-spacing: 0.6px;
  }
}
