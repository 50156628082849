@import '../../../scss/abstractions.scss';

.MuiPaper-root {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  overflow: hidden;
  font-family: $montserrat;
  background-color: #efefef !important;

  & .MuiMenuItem-root {
    font-family: $montserrat;
    letter-spacing: 0.8px;
    text-transform: uppercase;
  }

  & .auto-complete-input__highlight {
    font-weight: 700;
  }
}
