@import '../../scss/abstractions.scss';

.guide {
  position: relative;

  &__layout {
    display: block;
    @media screen and (min-width: $bp-md) {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  &__menu-icon {
    position: fixed;
    bottom: 5vh;
    right: 5rem;
    filter: drop-shadow(1px 2px 2px rgba($black, 0.7));
    z-index: 600;
    cursor: pointer;
    margin-right: calc(1 * (100% - 100vw));
    @media screen and (min-width: $bp-md) {
      display: none;
    }

    & path:nth-of-type(2),
    & path:nth-of-type(3) {
      transition: transform 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }

    &.menu-open path:nth-of-type(2) {
      transform: translateY(-13px);
    }

    &.menu-open path:nth-of-type(3) {
      transform: translateY(15px);
    }

    &.lower-z-index {
      z-index: 5;
    }
  }
}
