@import '../../scss/abstractions.scss';

.dashboard {
  position: relative;
  padding-top: 3rem;
  display: flex;
  background-color: $off-white;
  overflow-x: hidden;
  @media screen and (min-width: $bp-md-sm) {
    padding-top: 6rem;
  }

  &.menu-open.dashboard::after {
    opacity: 1;
    visibility: visible;
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgba($black, 0.7);
    z-index: 400;
    opacity: 0;
    visibility: hidden;
  }

  &__menu-icon {
    position: fixed;
    bottom: 5vh;
    right: 5vw;
    filter: drop-shadow(1px 2px 2px rgba($black, 0.7));
    z-index: 600;
    cursor: pointer;

    &.hide {
      z-index: 5;
    }

    @media screen and (min-width: $bp-md-sm) {
      display: none;
    }
  }
}

.dashboard-content {
  padding: 6rem 2rem 12rem 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media screen and (min-width: $bp-md-sm) {
    padding: 6rem 2rem 12rem 37rem;
  }
  @media screen and (min-width: $bp-md) {
    padding: 6rem 0rem 12rem 35rem;
  }
}
