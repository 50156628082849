@import '../../../scss/abstractions.scss';

.guide-content {
  opacity: 0;
  width: 100%;
  margin: 0 auto 0 0;
  padding: 0 2.5rem 3vh 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;

  animation: fade-in-content forwards 300ms 100ms
    cubic-bezier(0.39, 0.575, 0.565, 1);

  @media screen and (min-width: $bp-md) {
    padding: 0 3vw 5vh 3vw;
    width: 69vw;
  }

  &__layout {
    max-width: 100%;

    @media screen and (min-width: $bp-sm) {
      max-width: 50rem;
    }

    @media screen and (min-width: $bp-md-sm) {
      max-width: 65rem;
    }
    @media screen and (min-width: $bp-md) {
      max-width: 80rem;
    }
  }

  &__article {
    padding-top: 10vh;
    &:first-of-type {
      padding-top: 5vh;

      @media screen and (min-width: $bp-sm) {
        padding-top: 7vh;
      }
      @media screen and (min-width: $bp-md) {
        padding-top: 10vh;
      }
    }
  }

  &__article-step {
    padding-top: 10vh;
    @media screen and (min-width: $bp-md) {
      padding-top: 7vh;
    }
  }

  &__figure {
    width: 100%;
    margin: 7vh 0 4vh 0;

    @media screen and (min-width: $bp-md) {
      width: initial;
      margin: 7vh 0 4vh 0;
    }
  }

  & h3 {
    margin-bottom: 3vh;
    text-align: left;
  }

  & h4 {
    margin-bottom: 2vh;
    text-align: left;
  }
}
