@import '../../scss/abstractions.scss';

.page-title {
  position: relative;
  z-index: 0;
  padding-top: 15vh;
  display: flex;
  width: 100%;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 35vh;
  text-align: center;
  animation: bg-img-scale-in forwards 320ms cubic-bezier(0.39, 0.575, 0.565, 1);

  &--about-us {
    background-image: linear-gradient(rgba($black, 0.6), rgba($black, 0.6)),
      url('http://apettigrew.imgix.net/altninjas/samurai-fantasy.jpg?auto=compress&w=600&height=400');

    @media screen and (min-width: $bp-sm) {
      background-image: linear-gradient(rgba($black, 0.6), rgba($black, 0.6)),
        url('http://apettigrew.imgix.net/altninjas/samurai-fantasy.jpg?auto=compress&w=1280&h==700');
    }

    @media screen and (min-width: $bp-md) {
      background-image: linear-gradient(rgba($black, 0.6), rgba($black, 0.6)),
        url('http://apettigrew.imgix.net/altninjas/samurai-fantasy.jpg?auto=compress&min-w=1280&h=900');
    }
  }
  &--guide {
    background-image: linear-gradient(rgba($black, 0.6), rgba($black, 0.6)),
      url('http://apettigrew.imgix.net/altninjas/ceiling.jpg?auto=compress&w=600&h=400');
    @media screen and (min-width: $bp-sm) {
      background-image: linear-gradient(rgba($black, 0.6), rgba($black, 0.6)),
        url('http://apettigrew.imgix.net/altninjas/ceiling.jpg?auto=compress&w=1280&h=700');
    }

    @media screen and (min-width: $bp-md) {
      background-image: linear-gradient(rgba($black, 0.6), rgba($black, 0.6)),
        url('http://apettigrew.imgix.net/altninjas/ceiling.jpg?auto=compress&min-w=1280&h=900');
    }
  }
  &--contact-us {
    background-image: linear-gradient(rgba($black, 0.6), rgba($black, 0.6)),
      url('http://apettigrew.imgix.net/altninjas/city.jpg?auto=compress&w=320&h=150');
    @media screen and (min-width: $bp-xs) {
      background-image: linear-gradient(rgba($black, 0.6), rgba($black, 0.6)),
        url('http://apettigrew.imgix.net/altninjas/city.jpg?auto=compress&w=600&h=400');
    }
    @media screen and (min-width: $bp-sm) {
      background-image: linear-gradient(rgba($black, 0.6), rgba($black, 0.6)),
        url('http://apettigrew.imgix.net/altninjas/city.jpg?auto=compress&w=1280&h=700');
    }

    @media screen and (min-width: $bp-md) {
      background-image: linear-gradient(rgba($black, 0.6), rgba($black, 0.6)),
        url('http://apettigrew.imgix.net/altninjas/city.jpg?auto=compress&min-w=1280&h=900');
    }
  }

  @media screen and (min-width: $bp-sm) {
    padding-top: 13vh;
    height: 30vh;
    animation: bg-img-slide-in forwards 300ms
      cubic-bezier(0.39, 0.575, 0.565, 1);
  }

  @media screen and (min-width: $bp-md) {
    padding-top: 15vh;
    height: 40vh;
    z-index: 50;
    text-align: left;
  }

  & h2 {
    opacity: 0;
    width: 100%;
    max-width: 120rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 4rem;
    text-shadow: 0 2px 4px rgba($black, 0.5);

    animation: title-slide-in-right forwards 320ms 140ms
      cubic-bezier(0.25, 0.46, 0.45, 0.94);
    @media screen and (min-width: $bp-sm) {
      font-size: 6rem;
      animation: title-slide-in forwards 300ms 120ms
        cubic-bezier(0.39, 0.575, 0.565, 1);
    }
    @media screen and (min-width: $bp-md) {
      font-size: 6rem;
    }
  }
}

@keyframes title-slide-in {
  from {
    opacity: 0;
    transform: translateY(30px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes title-slide-in-right {
  from {
    opacity: 0;
    transform: translateX(30px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes bg-img-slide-in {
  from {
    background-position: 50% 35%;
  }

  to {
    background-position: 50% 20%;
  }
}

@keyframes bg-img-scale-in {
  from {
    background-size: 150%;
    background-position: 70% 50%;
  }

  to {
    background-size: 120%;
    background-position: 50% 50%;
  }
}
