@import '../../../scss/abstractions.scss';

.promo {
  padding: 15vh 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__laptop {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30vh;
    opacity: 0;
    transform: translateX(-60px);
    transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    @media screen and (min-width: $bp-md) {
      flex-direction: row;
      align-items: initial;
      margin-bottom: 12vh;
    }

    &.reveal {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &__mobile {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    opacity: 0;
    transform: translateX(60px);
    transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

    @media screen and (min-width: $bp-md) {
      flex-direction: row;
      align-items: initial;
    }

    &.reveal {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &__description {
    font-size: 1.6rem;
    line-height: 170%;
    letter-spacing: 0.5px;
    margin-top: 2vh;
  }

  &__img {
    &--laptop {
      position: relative;
      right: 0;
      width: 90vw;
      @media screen and (min-width: $bp-sm) {
        width: 70vw;
      }
      @media screen and (min-width: $bp-md) {
        right: 9vw;
        width: initial;
      }
    }
    &--mobile {
      position: relative;
      left: 0;
      height: auto;
      width: 25vh;
      @media screen and (min-width: $bp-sm) {
        width: 30vw;
      }
      @media screen and (min-width: $bp-md) {
        left: 2vw;
        height: 60rem;
        width: auto;
      }
    }
  }

  &__content {
    text-align: center;
    padding: 0 10px;
    max-width: 70rem;

    @media screen and (min-width: $bp-md) {
      text-align: left;
      padding: 0;
    }
    &--laptop {
      position: relative;
      top: 6vh;
      right: 0;
      margin: 0 auto;
      opacity: 0;
      transform: translateX(-30px);
      transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

      @media screen and (min-width: $bp-md) {
        right: 12vw;
        top: 10vh;
      }
      &.reveal {
        opacity: 1;
        transform: translateX(0);
      }
    }

    &--mobile {
      position: relative;
      left: 0;
      top: 6vh;
      margin: 0 auto;
      text-align: center;
      opacity: 0;
      transform: translateX(30px);
      transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

      @media screen and (min-width: $bp-md) {
        left: 5vw;
        top: 20vh;
        text-align: right;
      }
      &.reveal {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}
