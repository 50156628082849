@import '../../../scss/abstractions.scss';

.guide-menu {
  z-index: 0;
  width: 100%;
  position: fixed;
  top: 0;
  height: 200vh;
  background-color: $off-white;
  padding: 15vh 3vh 0 3vw;
  font-size: 1.6rem;
  line-height: 140%;
  text-transform: capitalize;
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms cubic-bezier(0.19, 1, 0.22, 1),
    visibility 300ms cubic-bezier(0.19, 1, 0.22, 1);

  &.menu-open {
    opacity: 1;
    visibility: visible;
    z-index: 500;

    @media screen and (min-width: $bp-md) {
      z-index: 0;
    }
  }

  @media screen and (min-width: $bp-md) {
    width: 30vw;
    position: absolute;
    top: 62.5%;
    padding: 50vh 0 0 3vw;
    opacity: 0;
    visibility: visible;
    animation: fade-in-content forwards 300ms 160ms
      cubic-bezier(0.39, 0.575, 0.565, 1);

    &.menu-fixed {
      top: 0;
      position: fixed;
      padding-top: 14.8vh;
    }

    &.menu-top {
      top: 0;
    }
  }

  & ul {
    max-width: 35rem;
    margin: 0 auto;
  }
  & li {
    backface-visibility: hidden;
    margin-bottom: 2vh;
    padding-left: 4rem;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 3px;
      border-radius: 3px;
      height: 3rem;

      transform: scaleY(0) translateY(-50%);
      background-color: $yellow;
      transition: all 150ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    &.active::after {
      transform: scaleY(1) translateY(-50%);
    }
  }

  & a {
    color: $black;
  }

  &__primary-link {
    font-weight: 700;
  }

  &__secondary-link {
    font-weight: 500;
    margin-left: 4rem;
  }
}
