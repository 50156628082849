// NIVO CHARTS MUST HAVE A PARENT CONTAINER WITH HEIGHT TO BE VISIBLE
@import '../../../scss/abstractions.scss';

.trade-freq-chart {
  height: 40vh;
  padding: 1rem 2rem;
  max-height: 400px;
  min-height: 325px;

  @extend %custom-line-chart-labels;

  @media screen and (min-width: $bp-md) {
    height: 50vh;
  }
}
