@import '../../../../scss/abstractions.scss';

.feature {
  width: 100%;
  max-width: 31rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 0.8px;
  line-height: 170%;
  margin-bottom: 10vh;
  transition: opacity $txn-slow;
  @media screen and (min-width: $bp-md) {
    width: 30%;
  }

  &.hide {
    opacity: 0;
  }

  &.reveal {
    opacity: 1;
  }

  &__img {
    height: 8.5rem;
  }
  &__title {
    font-size: 1.6rem;
    font-weight: 700;
    margin: 3rem 0;
    @media screen and (min-width: $bp-sm) {
      font-size: 1.8rem;
    }
  }

  &__description {
    font-size: 1.4rem;
    text-align: center;
    line-height: 170%;
    @media screen and (min-width: $bp-sm) {
      font-size: 1.6rem;
    }
  }
}
