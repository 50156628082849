@import '../../../scss/abstractions.scss';

.auth-form {
  opacity: 0;
  padding: 3vh 5vw 0 5vw;
  background-color: $black;
  border-radius: 8px;
  text-align: center;
  box-shadow: 1px 2px 5px rgba($black, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90rem;
  animation: fade-in-auth-form forwards 300ms
    cubic-bezier(0.39, 0.575, 0.565, 1);

  @media screen and (min-width: $bp-xs) {
    width: 90vw;
  }

  @media screen and (min-width: $bp-sm) {
    width: initial;
  }

  @media screen and (min-width: $bp-md) {
    padding: 3vh 3vw 0 3vw;
    text-align: left;
    align-items: flex-start;
  }

  &__link {
    font-size: 1.4rem;
    font-weight: bold;
    color: $yellow;
    text-transform: uppercase;
    line-height: 170%;
    letter-spacing: 0.4px;
    &:first-of-type {
      margin-bottom: 1vh;
      @media screen and (min-width: $bp-md) {
        margin-bottom: 0;
      }
    }
  }

  &__links-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 6vh;
  }

  &__success {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6vh;
    @media screen and (min-width: $bp-md) {
      // align-items: flex-start;
    }
  }

  &__text {
    color: $off-white;
    font-size: 1.4rem;
    line-height: 170%;
    letter-spacing: 0.4px;

    &--success {
      font-size: 1.4rem;
      margin-bottom: 5vh;

      @media screen and (min-width: $bp-md) {
        font-size: 2.2rem;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 222px;
    margin-bottom: 5vh;
    @media screen and (min-width: $bp-sm) {
      max-width: 34rem;
    }

    @media screen and (min-width: $bp-md) {
      align-items: flex-start;
      min-width: 15vw;
    }

    div {
      margin-bottom: 1vh;
    }

    input[type='submit'] {
      margin-top: 3vh;
    }

    label {
      font-size: 1.6rem;
      text-transform: capitalize;
      color: rgba($off-white, 0.7);
      &.Mui-focused {
        color: $yellow;
      }
    }

    .MuiFormControl-fullWidth {
      max-width: 40rem;
    }

    .MuiInput-input {
      color: $off-white;
      font-size: 2rem;
      max-width: 30rem;
    }

    .MuiInput-input {
      color: $off-white;
      font-size: 2rem;
      max-width: 30rem;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      -webkit-text-fill-color: $off-white;
      -webkit-box-shadow: 0 0 0px 1000px #000 inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    .MuiInput-underline {
      &:hover:not(:disabled):not(:focus):before {
        border-color: darken($yellow, 20%);
      }
    }

    .MuiInput-underline:after {
      border-color: $yellow;
    }

    .MuiInput-underline:before {
      border-color: rgba($off-white, 0.7);
    }
  }

  &__form-row {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $bp-sm) {
      font-size: 4rem;
      flex-direction: row;
    }
    & div:first-of-type {
      margin-right: 0;
      @media screen and (min-width: $bp-sm) {
        margin-right: 2vw;
      }
      @media screen and (min-width: $bp-md) {
        margin-right: 1.2vw;
      }
    }
    & div:nth-of-type(2) {
      margin-left: 0;
      @media screen and (min-width: $bp-sm) {
        margin-left: 2vw;
      }
      @media screen and (min-width: $bp-md) {
        margin-left: 1.2vw;
      }
    }
    &:nth-of-type(2) {
      margin-bottom: 3vh;
    }
  }

  & h2 {
    text-transform: uppercase;
    margin-bottom: 2vh;
    font-size: 2.7rem;
    @media screen and (min-width: $bp-sm) {
      font-size: 4rem;
    }
  }
}

@keyframes fade-in-auth-form {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
