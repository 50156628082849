@import '../../scss/abstractions.scss';

.header {
  position: absolute;
  width: 100%;
  padding: 0 2rem;
  display: flex;
  height: 6rem;
  align-items: center;
  z-index: 100;
  backface-visibility: hidden;
  transition: background-color 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);

  @media screen and (min-width: $bp-md-sm) {
    padding: 0 4rem;
  }

  &--black {
    background-color: $black;
  }

  &.header-fixed {
    position: fixed;
    box-shadow: 0 1px 4px rgba($black, 0.5);
    background-color: $black;
  }

  &__nav {
    width: 100%;
  }

  ul {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    background-image: radial-gradient(
      circle farthest-corner at 24.1% 68.8%,
      rgba(50, 50, 50, 1) 0%,
      rgba(0, 0, 0, 1) 99.4%
    );
    padding: 18vh 2.5rem 0 2.5rem;
    transition: all 400ms cubic-bezier(0.19, 1, 0.22, 1);
    &.menu-open {
      opacity: 1;
      visibility: visible;
      z-index: 200;
      transition: opacity 100ms cubic-bezier(0.55, 0.085, 0.68, 0.53),
        visibility 100ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
    }
    @media screen and (min-width: $bp-md-sm) {
      position: static;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-image: none;
      padding: 0;
      opacity: 1;
      visibility: visible;
    }
  }

  &__logo {
    margin-right: 4vw;
    position: relative;
    z-index: 300;
    height: 30px;
    @media screen and (min-width: $bp-md-sm) {
      height: 40px;
    }
  }

  &__nav-links-left {
    opacity: 0;
    transform: translateX(40px);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transition: all 300ms 200ms cubic-bezier(0.19, 1, 0.22, 1);
    &.menu-open {
      opacity: 1;
      transform: translateX(0);
    }
    @media screen and (min-width: $bp-md-sm) {
      opacity: 1;
      transform: translateX(0);
      flex-direction: row;
      align-items: center;
    }

    li {
      text-transform: uppercase;
      font-size: 2rem;
      font-weight: 700;
      letter-spacing: 0.6px;
      margin: 0 0 5vh 0;

      @media screen and (min-width: $bp-xs) {
        font-size: 3rem;
        letter-spacing: 1px;
      }

      @media screen and (min-width: $bp-md-sm) {
        margin: 0 3vw 0 0;
        font-size: 1.6rem;
        letter-spacing: 0.5px;
      }
      a {
        color: $off-white;
      }
    }
  }

  &__nav-links-right {
    opacity: 0;
    transform: translateX(60px);
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    transition: all 300ms 220ms cubic-bezier(0.19, 1, 0.22, 1);

    &.menu-open {
      opacity: 1;
      transform: translateX(0);
    }
    @media screen and (min-width: $bp-md-sm) {
      opacity: 1;
      transform: translateX(0);
      align-items: flex-start;
      flex-direction: row;
    }
    li {
      margin: 0 0 5vh 0;

      @media screen and (min-width: $bp-md-sm) {
        margin: 0 0 0 0.5rem;
      }
      a {
        @media screen and (min-width: $bp-xs) {
          font-size: 1.6rem;
          padding: 12px 20px;
          width: 14rem;
        }

        @media screen and (min-width: $bp-md-sm) {
          font-size: 1.2rem;
          padding: 8px 14px;
          width: 9rem;
        }
      }
    }
  }
  &__menu-icon {
    display: block;
    cursor: pointer;
    z-index: 300;

    @media screen and (min-width: $bp-md-sm) {
      display: none;
    }
  }

  &__menu-img {
    opacity: 0;
    transform: translateX(-40px);
    position: fixed;
    bottom: 2vh;
    left: 0;
    width: 13rem;
    transition: all 300ms 240ms cubic-bezier(0.19, 1, 0.22, 1);

    &.menu-open {
      opacity: 1;
      transform: translateX(0);
    }
    @media screen and (min-width: $bp-xs) {
      width: 15.5rem;
    }

    @media screen and (min-width: $bp-md-sm) {
      display: none;
    }
  }

  & .custom-mui-select {
    position: relative;
    right: 8rem;
    margin-left: 0;

    @media screen and (min-width: $bp-md-sm) {
      right: 0rem;
      margin-left: 5rem;
    }
  }

  & .MuiFormLabel-root {
    font-size: 1.6rem;
    color: $yellow;
    text-transform: capitalize;
  }

  & .MuiInputBase-root {
    font-size: 1.2rem;
    color: $off-white;
    text-transform: uppercase;
    svg {
      fill: $off-white;
    }
  }

  & .MuiSelect-root {
    width: 150px;
  }
}
