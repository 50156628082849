@import '../../../scss/abstractions.scss';

.features {
  display: flex;
  align-items: center;
  justify-content: center;

  &__layout {
    margin: 15vh 0;
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;

    h2 {
      text-align: center;
      width: 100%;
      line-height: 170%;
      @media screen and (min-width: $bp-sm) {
        max-width: 60rem;
      }

      @media screen and (min-width: $bp-md) {
        max-width: 78.8rem;
      }
    }
  }

  &__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10vh;
    align-items: center;
    flex-direction: column;
    @media screen and (min-width: $bp-md) {
      margin-top: 15vh;

      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &__list-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2vh;

    @media screen and (min-width: $bp-md) {
      margin-top: 5vh;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
    }
    .feature {
      @media screen and (min-width: $bp-md) {
        margin: 0 1.5vw;
      }
    }
  }
}
