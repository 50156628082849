@import '../../../scss/abstractions.scss';

.account-status {
  width: 100%;
  padding: 4vh 5vw;
  color: $red;
  background-color: $black;
  border-radius: 8px;
  box-shadow: $bs-dash-component;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  @media screen and (min-width: $bp-lg) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  &--complete {
    color: $green;
  }
  &__msg-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__msg {
    font-weight: 700;
    font-size: 2rem;
    text-transform: uppercase;
    margin-bottom: 5px;
    letter-spacing: 0.8px;
    @media screen and (min-width: $bp-sm) {
      font-size: 3rem;
    }
  }

  &__sub-text {
    color: $grey-standard;
    font-size: 1.6rem;
    letter-spacing: 0.8px;
    line-height: 170%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: $bp-sm) {
      font-size: 2rem;
    }
  }

  &__todos {
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.8px;
    margin-top: 4rem;
    text-transform: uppercase;
    @media screen and (min-width: $bp-sm) {
      font-size: 1.6rem;
    }
    @media screen and (min-width: $bp-lg) {
      margin-top: 0;
    }
  }

  &__todo-item {
    display: flex;
    align-items: center;
    margin: 1vh 0;
    &--binance {
      position: relative;
      right: 2px;
    }
  }

  &__todo-icon {
    margin-right: 2px;
    &--profile {
      margin-right: 5px;
    }
  }
  & .MuiSwitch-track {
    background-color: $grey-light;
  }

  & .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: $green;
  }

  & .MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track {
    background-color: $grey-light;
  }

  & .MuiSwitch-colorSecondary.Mui-checked {
    color: $green;
  }
}
