@import '../../scss/abstractions.scss';

.auth {
  padding: 6rem 10vw 6rem 10vw;
  display: flex;
  justify-content: center;
  align-items: center;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 100vh;
  animation: fade-in-auth-bg 200ms forwards cubic-bezier(0.25, 0.46, 0.45, 0.94);

  @media screen and (min-width: $bp-xs) {
    padding: 6rem 0 5vh 0;
    animation: fade-in-auth-bg-xs 200ms forwards
      cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  @media screen and (min-width: $bp-sm) {
    padding: 6rem 0 0 0;
    animation: fade-in-auth-bg-sm 200ms forwards
      cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  @media screen and (min-width: $bp-md) {
    padding: 6rem 0 0 0;
    animation: fade-in-auth-bg-sm 200ms forwards
      cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
}

@keyframes fade-in-auth-bg {
  from {
    background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1)),
      url('http://apettigrew.imgix.net/altninjas/hooded-ninja.jpg?auto=compress&w=320');
  }

  to {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('http://apettigrew.imgix.net/altninjas/hooded-ninja.jpg?auto=compress&w=320');
  }
}

@keyframes fade-in-auth-bg-xs {
  from {
    background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1)),
      url('http://apettigrew.imgix.net/altninjas/hooded-ninja.jpg?auto=compress&w=600');
  }

  to {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('http://apettigrew.imgix.net/altninjas/hooded-ninja.jpg?auto=compress&w=600');
  }
}

@keyframes fade-in-auth-bg-sm {
  from {
    background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1)),
      url('http://apettigrew.imgix.net/altninjas/hooded-ninja.jpg?auto=compress&w=1280');
  }

  to {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('http://apettigrew.imgix.net/altninjas/hooded-ninja.jpg?auto=compress&w=1280');
  }
}

@keyframes fade-in-auth-bg-md {
  from {
    background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1)),
      url('http://apettigrew.imgix.net/altninjas/hooded-ninja.jpg?auto=compress&min-w=1280');
  }

  to {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('http://apettigrew.imgix.net/altninjas/hooded-ninja.jpg?auto=compress&min-w=1280');
  }
}
