@import '../../../scss/abstractions.scss';

.trade-profile {
  @extend %dash-component-trade-profile;
  height: 35vh;
  min-height: 400px;
  position: relative;

  @media screen and (min-width: $bp-sm) {
    min-height: 375;
  }

  @media screen and (min-width: $bp-md) {
    min-height: 400px;
  }
  &__layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;

    p {
      margin-bottom: 3vh;
      @extend %fade-in-dash-page;
    }

    button {
      margin-top: 4rem;
    }
  }

  & .MuiFormControl-root {
    width: 100%;
    @extend %fade-in-dash-page;

    @media screen and (min-width: $bp-sm) {
      width: 70%;
    }
    @media screen and (min-width: $bp-md) {
      width: 40%;
    }

    & .MuiFormGroup-root {
      justify-content: space-around;
      flex-direction: row;
    }
  }

  & .MuiTypography-body1 {
    font-size: 1rem;
    text-transform: uppercase;
    @media screen and (min-width: $bp-sm) {
      font-size: 1.4rem;
    }
    @media screen and (min-width: $bp-md) {
      font-size: 1.6rem;
    }
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: $yellow;
  }

  & .MuiIconButton-colorSecondary:hover,
  & .MuiIconButton-colorSecondary:focus,
  & .MuiRadio-colorSecondary.Mui-checked:hover,
  & .MuiRadio-colorSecondary.Mui-checked:focus {
    background-color: rgba($yellow, 0.2);
  }

  & .PrivateSwitchBase-root-15 {
    padding: 5px;
  }

  &__progress-display {
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 1rem;

    @media screen and (min-width: $bp-sm) {
      width: 55%;
    }
    @media screen and (min-width: $bp-md-sm) {
      width: 65%;
    }
    @media screen and (min-width: $bp-md) {
      width: 50%;
    }
  }

  &__progress-icon {
    transition: all 400ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  &__progress-icon.fill {
    fill: $yellow;
  }

  &__progress-bar {
    opacity: 0;
    visibility: hidden;
    transform: translateY(40px);
    animation: progress-slide-in-bottom forwards 300ms
      cubic-bezier(0.39, 0.575, 0.565, 1);
  }

  &__nav-button {
    cursor: pointer;
    width: 6rem;
    position: relative;
    bottom: 5px;
    opacity: 0;
    visibility: hidden;
    @media screen and (min-width: $bp-sm) {
      width: initial;
    }

    &.sword {
      &--left {
        transform: translateX(-40px);
        animation: sword-slide-in-left forwards 300ms 100ms
          cubic-bezier(0.39, 0.575, 0.565, 1);
      }

      &--right {
        transform: translateX(40px);
        animation: sword-slide-in-right forwards 300ms 100ms
          cubic-bezier(0.39, 0.575, 0.565, 1);
      }
    }
  }

  &__button-group {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.disabled,
    &.disabled img {
      cursor: default;
      opacity: 0.5;
    }
    span {
      opacity: 0;
      visibility: hidden;
      transform: translateY(40px);
      font-size: 1.2rem;
      text-transform: uppercase;
      position: relative;
      top: 2rem;
      font-weight: 500;
      letter-spacing: 0.8px;
      color: $grey-dark;
      animation: progress-slide-in-bottom 200ms 300ms forwards
        cubic-bezier(0.39, 0.575, 0.565, 1);
      @media screen and (min-width: $bp-sm) {
        top: 3rem;
      }
    }
  }

  &__submit-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    @extend %fade-in-dash-page;
  }
}

@keyframes sword-slide-in-left {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-40px);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}

@keyframes sword-slide-in-right {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateX(40px);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}

@keyframes progress-slide-in-bottom {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(40px);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
