@import './scss/abstractions.scss';

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

body {
  font-family: $montserrat;
  color: $black;
  padding: 0 !important;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

img {
  display: block;
}

button {
  border: none;
}

.custom-mui-form {
  label {
    font-size: 1.6rem;
    text-transform: capitalize;
    color: rgba($black, 0.5);
    font-family: $montserrat;

    &.Mui-focused {
      color: $yellow;
    }

    &.MuiFormLabel-filled {
      color: $yellow;
    }
  }

  input:-internal-autofill-selected {
    background-color: transparent;
  }

  .MuiInput-underline {
    &:hover:not(:disabled):not(:focus):before {
      border-color: lighten($black, 50%);
    }
  }

  .MuiInput-input {
    color: $black;
    font-size: 1.6rem;

    @media screen and (min-width: $bp-sm) {
      font-size: 2rem;
    }
  }

  .MuiInput-underline:after {
    border-color: rgba($black, 0.5);
  }

  .MuiInput-underline:before {
    border-color: rgba($black, 0.5);
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  div.MuiFormControl-root {
    max-width: 200px;
  }

  &--half-width {
    div.MuiFormControl-root {
      max-width: 100%;

      @media screen and (min-width: $bp-sm) {
        max-width: 48%;
      }
    }
  }

  & .MuiInputBase-root {
    font-family: $montserrat;
  }

  & .MuiFormHelperText-root.Mui-error {
    margin: 0;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.8px;
    font-family: $montserrat;
    position: relative;
    bottom: 10px;
  }
}
