@import '../../../scss/abstractions.scss';

.filter-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3vh 2vw 4vh 2vh;
  background-color: $black;
  border-radius: 8px;
  margin-bottom: 2rem;
  box-shadow: $bs-dash-component;
  position: relative;

  & .MuiAvatar-root {
    width: 8rem;
    height: 8rem;
    box-shadow: 0 1px 3px rgba($black, 0.5);
    @media screen and (min-width: $bp-sm) {
      width: 12rem;
      height: 12rem;
    }
    @media screen and (min-width: $bp-md) {
      width: 6rem;
      height: 6rem;
    }
  }

  &__avatar-group {
    position: relative;
    margin-right: 10px;
    transform: translateY(0);
    margin-bottom: 2vh;
    @media screen and (min-width: $bp-md) {
      margin-bottom: 0;
      transform: translateY(4px);
    }
  }

  &__avatar-status {
    position: absolute;
    width: 2.2rem;
    height: 2.2rem;
    top: 2px;
    right: 2px;
    z-index: 5;
    border-radius: 50%;
    background-color: $green;
    box-shadow: 0 1px 3px rgba($black, 0.5);
    &--offline {
      background-color: $red;
    }
    @media screen and (min-width: $bp-sm) {
      top: 3px;
      right: 8px;
      width: 2.5rem;
      height: 2.5rem;
    }
    @media screen and (min-width: $bp-md) {
      top: -1px;
      right: 2px;
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &__date-select-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2vh;
    @media screen and (min-width: $bp-sm) {
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
    }
    @media screen and (min-width: $bp-md) {
      margin-bottom: 0;
    }
  }

  &__signaler-select-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1vh;
    @media screen and (min-width: $bp-md) {
      flex-direction: row;
      margin-bottom: 0;
    }
  }

  & .MuiInputLabel-root {
    font-size: 1.6rem;
    color: $yellow;
    text-transform: capitalize;
    letter-spacing: 0.8px;

    &.Mui-focused {
      color: $yellow;
    }
  }

  & .MuiSelect-root {
    text-transform: uppercase;
    & .Mui-disabled {
      color: $grey-dark;
    }
  }

  & .Mui-disabled .MuiSelect-icon {
    fill: $grey-dark;
  }

  & .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: solid;
  }

  & .MuiSelect-select:focus {
    background-color: transparent;
  }

  & .MuiSelect-selectMenu {
    color: $off-white;
    &:focus {
      color: $off-white;
    }
  }

  & .MuiSelect-icon {
    color: $off-white;
  }

  & .MuiInputBase-root {
    width: 60vw;
    font-size: 2rem;
    text-align: left;
    border-radius: 8px;
    font-family: $montserrat;
    &:first-of-type {
      margin-bottom: 4rem;
      @media screen and (min-width: $bp-sm) {
        margin-bottom: 0;
      }
    }
    @media screen and (min-width: $bp-sm) {
      width: 35vw;
    }
    @media screen and (min-width: $bp-md-sm) {
      width: 20vw;
    }
  }

  & .MuiInput-underline:before {
    border-color: rgba($off-white, 0.4);
  }

  & .MuiInput-underline:after {
    border-color: rgba($off-white, 1);
  }

  & .MuiInput-underline {
    &:hover:not(:disabled):not(:focus):before {
      border-bottom: 1px solid $grey-dark;
    }
  }

  &__timeframe-display {
    font-size: 2rem;
    font-weight: 500;
    color: $grey-dark;
    margin-top: 2rem;
    text-transform: uppercase;
    @media screen and (min-width: $bp-sm) {
      font-size: 3rem;
      margin-right: 0;
    }
    @media screen and (min-width: $bp-lg) {
      margin-right: 3vw;
    }
  }

  &--flex {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3vh 2vw 4vh 2vw;

    & .filter-form__date-select-group {
      width: initial;
    }

    @media screen and (min-width: $bp-md) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    & .MuiInputLabel-root {
      font-size: 1.6rem;
      color: $yellow;
      text-transform: capitalize;
      letter-spacing: 0.8px;

      &.Mui-focused {
        color: $yellow;
      }
    }

    & .MuiInputBase-root {
      font-size: 1.6rem;
      width: 50vw;
      text-align: left;
      @media screen and (min-width: $bp-md-sm) {
        width: 30vw;
      }

      @media screen and (min-width: $bp-md) {
        width: 15vw;
      }
    }
  }
}

ul .MuiMenuItem-root {
  font-size: 1.8rem;
  display: flex;
  justify-content: center;
}
