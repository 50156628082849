@import '../../scss/abstractions.scss';

.MuiPaper-root.MuiSnackbarContent-root {
  background-color: $yellow !important;
  font-size: 1.4rem;
  text-transform: capitalize;
  color: $black;
  font-weight: 500;
  font-family: $montserrat;
  letter-spacing: 0.8px;
}
