@import '../../scss/abstractions.scss';

.register-cta {
  position: relative;
  z-index: 50;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url('http://apettigrew.imgix.net/altninjas/bitcoin-falling.jpg?auto=compress&w=320');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 12vh 0 15vh 0;

  @media screen and (min-width: $bp-xs) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url('http://apettigrew.imgix.net/altninjas/bitcoin-falling.jpg?auto=compress&w=600');
  }

  @media screen and (min-width: $bp-sm) {
    padding: 8vh 0 10vh 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url('http://apettigrew.imgix.net/altninjas/bitcoin-falling.jpg?auto=compress&w=1280');
  }

  @media screen and (min-width: $bp-md) {
    padding: 15vh 0 18vh 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
      url('http://apettigrew.imgix.net/altninjas/bitcoin-falling.jpg?auto=compress&min-w=1280');
  }

  & h2 {
    margin-bottom: 3vh;
  }
}
