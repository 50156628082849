@import '../../../scss/abstractions.scss';

.binance-form {
  @extend %dash-component-form;

  &__acount-linked {
    font-size: 1.6rem;
    letter-spacing: 0.8px;
    line-height: 170%;
    text-align: center;

    button {
      background-color: transparent;
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 700;
      cursor: pointer;
      margin: 0 0 1.5rem 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (min-width: $bp-sm) {
        margin: 0 2vw 1.5rem 2vw;
      }
      @media screen and (min-width: $bp-md-sm) {
        margin: 0 0 1.5rem 0;
      }

      & img {
        width: 2rem;
        margin-right: 7px;
      }
    }
  }

  &__btn-container {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    justify-content: flex-start;
    @media screen and (min-width: $bp-sm) {
      flex-direction: row;
      justify-content: center;
    }
    @media screen and (min-width: $bp-md-sm) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__update-btn {
    color: $yellow;
  }
  &__clear-btn {
    color: $red;
  }

  div {
    margin-bottom: 2rem;
  }

  input[type='submit'] {
    margin: 2vh 0;
  }
}
