// NIVO CHARTS MUST HAVE A PARENT CONTAINER WITH HEIGHT TO BE VISIBLE
@import '../../../scss/abstractions.scss';

.coin-allocation-chart {
  height: 40vh;
  width: 100%;
  padding: 0;
  max-height: 400px;
  min-height: 325px;
  padding: 3rem;

  @media screen and (min-width: $bp-md) {
    height: 50vh;
  }
}
