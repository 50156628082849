@import '../../../scss/abstractions.scss';

.stat-box {
  border-radius: 8px;
  width: 42vw;
  margin: 0 0 1.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba($black, 0.2);
  background-color: $black;
  padding: 1rem 0 3.5rem 0;
  @media screen and (min-width: $bp-sm) {
    width: 22vw;
  }

  @media screen and (min-width: $bp-md-sm) {
    width: 14vw;
  }
  @media screen and (min-width: $bp-md) {
    width: 20vw;
    margin: 0 1rem;
    flex-direction: row;
    padding: 0;
    background-color: transparent;
  }

  &--cursor-pointer {
    backface-visibility: hidden;
    cursor: pointer;
    transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    &:hover,
    &:focus {
      transform: scale(1.03);
      box-shadow: $bs-dash-component;
    }
  }

  &--half-of-container {
    width: 48%;

    &:last-of-type {
      width: 100%;
      @media screen and (min-width: $bp-sm) {
        width: 31%;
      }
    }

    @media screen and (min-width: $bp-sm) {
      width: 31%;
    }
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &__icon-container {
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    @media screen and (min-width: $bp-md) {
      background-color: $black;
      width: 30%;
      padding: 1rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    flex: 1;
    text-transform: uppercase;
    @media screen and (min-width: $bp-md) {
      background-color: $white;
    }
  }
  &__stat {
    font-weight: 700;
    font-size: 2.5rem;
    letter-spacing: 1px;
    &--coid {
      font-size: 1.4rem;
      word-break: break-all;
      max-width: 80%;
      text-align: center;
    }
    &--green {
      color: $green;
    }
    &--blue {
      color: $blue;
    }
    &--yellow {
      color: $yellow;
    }
    &--red {
      color: $red;
    }
    &--violet {
      color: $violet;
    }
    &--white {
      color: $white;
    }
  }

  &__stat-description {
    font-size: 1rem;
    font-weight: 500;
    color: $grey-standard;
    margin-top: 5px;
  }
}
