@import '../../scss/abstractions.scss';

.dash-home {
  @extend %fade-in-dash-page;
  @extend %dash-page-min-height;
  width: 100%;
  @media screen and (min-width: $bp-md) {
    width: 60vw;
    max-width: 1200px;
  }

  &__stat-box-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    @media screen and (min-width: $bp-md) {
      flex-wrap: nowrap;
    }

    &--trade-of-the-day {
      flex-direction: column;
    }
  }
  &__row {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media screen and (min-width: $bp-md) {
      align-items: flex-start;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
