@import '../../scss/abstractions.scss';

.dash-trade-interface {
  @extend %fade-in-dash-page;
  @extend %dash-page-min-height;
  width: 100%;
  @media screen and (min-width: $bp-md) {
    width: 60vw;
    max-width: 1200px;
  }

  & .MuiFormControl-root {
    &.dash-trade-interface__user-select {
      align-self: center;

      @media screen and (min-width: $bp-sm) {
        align-self: flex-start;
      }
    }
  }

  & .MuiSelect-root {
    font-size: 1.6rem;

    @media screen and (min-width: $bp-sm) {
      font-size: 2rem;
    }
  }

  & .MuiSelect-select:focus {
    background-color: transparent;
  }

  & .MuiSelect-selectMenu {
    color: $black;
    &:focus {
      color: $black;
    }
  }

  & .MuiInputLabel-animated {
    color: $yellow;
  }

  & .MuiFormLabel-root.Mui-focused {
    color: $yellow;
  }

  & .MuiFormLabel-root {
    font-size: 1.6rem;
    text-transform: capitalize;
  }

  .MuiInputLabel-formControl {
    top: 0px;
    @media screen and (min-width: $bp-sm) {
      top: -14px;
    }
  }

  & .MuiInputBase-root {
    width: 60vw;
    font-size: 1.6rem;
    text-align: left;
    border-radius: 8px;
    font-family: $montserrat;
    text-transform: uppercase;
    margin-bottom: 4vh;
    @media screen and (min-width: $bp-sm) {
      margin: 0;
      width: 40vw;
      font-size: 2rem;
    }
    @media screen and (min-width: $bp-md-sm) {
      width: 25vw;
    }
    @media screen and (min-width: $bp-md) {
      width: 20vw;
    }
  }

  & .MuiInput-underline:before {
    border-color: rgba($black, 0.4);
  }

  & .MuiInput-underline:after {
    border-color: rgba($black, 0.5);
  }

  & .MuiInput-underline {
    &:hover:not(:disabled):not(:focus):before {
      border-color: rgba($black, 0.5);
    }
  }

  &__load-user {
    @extend %dash-component-form;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__user-profile {
    margin: 4vh 0 7vh 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media screen and (min-width: $bp-sm) {
      margin: 7vh 0;

      flex-direction: row;
    }
  }
  &__user-profile-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60vw;
    letter-spacing: 0.8px;
    &:not(:last-of-type) {
      margin-bottom: 4vh;

      @media screen and (min-width: $bp-sm) {
        margin-bottom: 0;
      }
    }

    @media screen and (min-width: $bp-sm) {
      align-items: flex-start;
      width: 30%;
      margin-bottom: 0;
    }

    & span:first-of-type {
      text-transform: capitalize;
      color: $blue;
      font-size: 1.2rem;
      font-weight: 700;
    }

    & span:last-of-type {
      font-size: 1.8rem;
      text-transform: uppercase;
      line-height: 170%;
    }
  }

  &__signal-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    & .MuiPaper-root {
      position: absolute;
      width: 100%;
      z-index: 5;
    }

    & .react-autosuggest__container {
      position: relative;
    }

    & input[type='submit'] {
      margin-top: 5vh;
      align-self: center;
      @media screen and (min-width: $bp-sm) {
        align-self: flex-start;
      }
    }
  }

  &__input-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin: 0;

    @media screen and (min-width: $bp-sm) {
      flex-direction: row;
      margin: 0 0 7vh 0;
    }

    &:first-of-type {
      margin: 0;

      @media screen and (min-width: $bp-sm) {
        margin: 2vh 0 7vh 0;
      }
    }

    &--three {
      justify-content: flex-start;

      & .MuiFormControl-root {
        margin: 0;
        @media screen and (min-width: $bp-sm) {
          margin: 0 4vw 0 0;
        }
        &:last-of-type {
          margin: 0;
        }
        &:first-of-type {
          margin: 0;
          @media screen and (min-width: $bp-sm) {
            margin: 0 4vw 0 0;
          }
        }
      }
      & .MuiInputBase-root {
        width: 35vw;
        @media screen and (min-width: $bp-sm) {
          width: 20vw;
        }
        @media screen and (min-width: $bp-md-sm) {
          width: 13vw;
        }
        @media screen and (min-width: $bp-md) {
          width: 10vw;
        }
      }
    }
  }
}
