@import '../../../scss/abstractions.scss';

.register-signaler-form {
  @extend %dash-component-form;

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;

    div {
      margin-bottom: 3vh;
    }
  }

  & .warning {
    color: $red;
    font-weight: 500;
  }
}
