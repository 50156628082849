@import '../../../scss/abstractions.scss';

.landing {
  position: relative;
  padding-top: 6rem;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  animation: fade-in-bg-sm 2000ms forwards cubic-bezier(0.19, 1, 0.22, 1);

  @media screen and (min-width: $bp-xs) {
    min-height: 90vh;
  }

  @media screen and (min-width: $bp-md) {
    animation: fade-in-bg-md 2000ms forwards cubic-bezier(0.19, 1, 0.22, 1);
    min-height: 95vh;
    align-items: center;
  }

  &__content {
    padding: 0 2rem;
    text-align: center;
    margin-top: 4vh;
    @media screen and (min-width: $bp-xs) {
      margin-top: 7vh;
    }
    @media screen and (min-width: $bp-sm) {
      width: 90%;
      padding: 0;
      margin-top: 10vh;
    }

    @media screen and (min-width: $bp-md) {
      width: 68.5%;
      text-align: left;
      margin-top: 0;
    }
  }

  &__headline {
    opacity: 0;
    color: $off-white;
    text-transform: uppercase;
    line-height: 140%;
    letter-spacing: 1.32px;
    font-weight: 900;
    font-size: 3rem;
    animation: slide-in-top forwards 600ms 400ms
      cubic-bezier(0.25, 0.46, 0.45, 0.94);
    @media screen and (min-width: $bp-xs) {
      font-size: 3.5rem;
    }
    @media screen and (min-width: $bp-sm) {
      font-size: 7rem;
    }
    @media screen and (min-width: $bp-md) {
      font-size: 7rem;
    }
  }

  &__sub-headline {
    opacity: 0;
    color: $off-white;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 200%;
    animation: slide-in-top forwards 600ms 200ms
      cubic-bezier(0.25, 0.46, 0.45, 0.94);

    @media screen and (min-width: $bp-xs) {
      font-size: 1.6rem;
    }

    @media screen and (min-width: $bp-sm) {
      font-size: 2.5rem;
    }

    &:first-of-type {
      margin-top: 6vh;

      @media screen and (min-width: $bp-xs) {
        margin-top: 4vh;
      }

      @media screen and (min-width: $bp-sm) {
        margin-top: 5vh;
      }

      @media screen and (min-width: $bp-md) {
        margin-top: 3vh;
      }
    }
    &:last-of-type {
      margin-bottom: 12vh;

      @media screen and (min-width: $bp-xs) {
        margin-bottom: 8vh;
      }

      @media screen and (min-width: $bp-sm) {
        margin-bottom: 10vh;
      }
      @media screen and (min-width: $bp-md) {
        margin-bottom: 8vh;
      }
    }
  }

  &__btn-container {
    opacity: 0;
    animation: slide-in-top forwards 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
}

@keyframes slide-in-top {
  from {
    opacity: 0;
    transform: translateY(30px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-bg {
  from {
    background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1)),
      url('http://apettigrew.imgix.net/altninjas/ninja.jpg?auto=compress&w=320');
  }

  to {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url('http://apettigrew.imgix.net/altninjas/ninja.jpg?auto=compress&w=320');
  }
}

@keyframes fade-in-bg-xs {
  from {
    background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1)),
      url('http://apettigrew.imgix.net/altninjas/ninja.jpg?auto=compress&w=600');
  }

  to {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url('http://apettigrew.imgix.net/altninjas/ninja.jpg?auto=compress&w=600');
  }
}

@keyframes fade-in-bg-sm {
  from {
    background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1)),
      url('http://apettigrew.imgix.net/altninjas/ninja.jpg?auto=compress&w=1280');
  }

  to {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url('http://apettigrew.imgix.net/altninjas/ninja.jpg?auto=compress&w=1280');
  }
}

@keyframes fade-in-bg-md {
  from {
    background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1)),
      url('http://apettigrew.imgix.net/altninjas/ninja.jpg?auto=compress&min-w=1280');
  }

  to {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url('http://apettigrew.imgix.net/altninjas/ninja.jpg?auto=compress&min-w=1280');
  }
}
