@import '../../scss/abstractions.scss';

.dash-signal-history {
  @extend %fade-in-dash-page;
  @extend %dash-page-min-height;
  width: 100%;
  @media screen and (min-width: $bp-md) {
    width: 60vw;
    max-width: 1200px;
  }
}
