// NIVO CHARTS MUST HAVE A PARENT CONTAINER WITH HEIGHT TO BE VISIBLE
@import '../../../scss/abstractions.scss';

.performance-chart {
  height: 40vh;
  width: 100%;
  padding: 0;
  max-height: 500px;
  min-height: 325px;
  padding: 1rem 1rem;

  @extend %custom-line-chart-labels;

  @media screen and (min-width: $bp-md) {
    height: 50vh;
    padding: 2rem;
  }
}
