@import '../../scss/abstractions.scss';

.dash-activity {
  @extend %fade-in-dash-page;
  @extend %dash-page-min-height;
  width: 100%;
  @media screen and (min-width: $bp-md) {
    width: 60vw;
    max-width: 1200px;
  }
}

.activity-table {
  &__inputs {
    @extend %dash-component-form;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    @media screen and (min-width: $bp-sm) {
      flex-direction: row;
    }
  }

  &__count {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: initial;
    margin-bottom: 3vh;
    @media screen and (min-width: $bp-sm) {
      width: 10vw;
      margin-bottom: 0;
    }
    @media screen and (min-width: $bp-md-sm) {
      width: 12vw;
    }
    @media screen and (min-width: $bp-md) {
      width: 7vw;
    }
  }

  &__count-label {
    font-size: 2rem;
    color: $yellow;
    text-transform: uppercase;
    @media screen and (min-width: $bp-sm) {
      font-size: 1.6rem;
    }
  }

  &__count-number {
    font-size: 3rem;
  }

  & .MuiFormControl-root {
    &.dash-trade-interface__user-select {
      align-self: center;

      @media screen and (min-width: $bp-sm) {
        align-self: flex-start;
      }
    }
  }

  & .MuiSelect-root {
    font-size: 1.6rem;

    @media screen and (min-width: $bp-sm) {
      font-size: 2rem;
    }
  }

  & .MuiSelect-select:focus {
    background-color: transparent;
  }

  & .MuiSelect-selectMenu {
    color: $black;
    &:focus {
      color: $black;
    }
  }

  & .MuiInputLabel-animated {
    color: $yellow;
  }

  & .MuiFormLabel-root.Mui-focused {
    color: $yellow;
  }

  & .MuiFormLabel-root {
    font-size: 1.6rem;
    text-transform: capitalize;
  }

  .MuiInputLabel-formControl {
    top: 0px;
    @media screen and (min-width: $bp-sm) {
      top: -14px;
    }
  }

  & .MuiInputBase-root {
    width: 60vw;
    font-size: 1.6rem;
    text-align: left;
    border-radius: 8px;
    font-family: $montserrat;
    text-transform: uppercase;
    margin-bottom: 4vh;
    @media screen and (min-width: $bp-sm) {
      margin: 0;
      width: 30vw;
      font-size: 2rem;
    }
    @media screen and (min-width: $bp-md-sm) {
      width: 20vw;
    }
    @media screen and (min-width: $bp-md) {
      width: 15vw;
    }
  }

  & .MuiInput-underline:before {
    border-color: rgba($black, 0.4);
  }

  & .MuiInput-underline:after {
    border-color: rgba($black, 0.5);
  }

  & .MuiInput-underline {
    &:hover:not(:disabled):not(:focus):before {
      border-color: rgba($black, 0.5);
    }
  }
}
