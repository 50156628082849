@import '../../../../scss/abstractions.scss';

.crypto-slider {
  width: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 7vh auto 10vh auto;
  @media screen and (min-width: $bp-sm) {
    width: 55vw;
  }
  @media screen and (min-width: $bp-md) {
    width: 1000px;
  }

  &__btn {
    cursor: pointer;
    background-color: $grey-light;
    border-radius: 8px;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 100ms cubic-bezier(0.77, 0, 0.175, 1),
      opacity 300ms 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
      transform 300ms 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    &:hover,
    &:focus {
      background-color: darken($grey-light, 10%);
    }
    &.disabled {
      img {
        opacity: 0.3;
      }
      background-color: rgba($grey-light, 0.3);
      &:hover,
      &:focus {
        background-color: $grey-light;
      }
    }

    &--left {
      opacity: 0;
      transform: translateX(-40px);
    }

    &--right {
      opacity: 0;
      transform: translateX(40px);
    }

    &.reveal {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &__display {
    width: 24rem;
    height: 25rem;
    overflow: hidden;
    position: relative;
    opacity: 0;
    transform: translateY(40px);
    transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    &.reveal {
      opacity: 1;
      transform: translateY(0);
    }
    @media screen and (min-width: $bp-md) {
      width: 72rem;
    }
  }

  &__display-wrapper {
    position: absolute;
    left: -8px;
    top: 10px;
    display: flex;
    transition: transform 800ms cubic-bezier(0.19, 1, 0.22, 1);
    @media screen and (min-width: $bp-xs) {
      left: 0;
    }
  }

  &__loading {
    width: 60px;
    height: 60px;
    position: relative;
    transform-origin: center center;
    transform: translate(90px, 90px);
    @media screen and (min-width: $bp-md) {
      transform: translate(315px, 70px);
      width: 100px;
      height: 100px;
    }
  }

  &__loading-bounce {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $black;
    position: absolute;
    opacity: 0.6;
    left: 0;
    top: 0;
    animation: bounce 1.8s infinite ease-in-out;

    &--2 {
      animation-delay: -0.9s;
    }
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}
