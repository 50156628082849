@import '../../scss/abstractions.scss';

.dash-settings {
  @extend %fade-in-dash-page;
  @extend %dash-page-min-height;
  width: 100%;
  @media screen and (min-width: $bp-md) {
    width: 60vw;
    max-width: 1200px;
  }

  &__row {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media screen and (min-width: $bp-md) {
      align-items: flex-start;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
