@import '../../../scss/abstractions.scss';

.stats {
  margin: 0 0 5vh 0;
  @media screen and (min-width: $bp-md) {
    margin: 10vh 0 7vh 0;
  }
  &__layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    max-width: 1700px;
    margin: 0 auto;
    transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

    @media screen and (min-width: $bp-md) {
      flex-direction: row;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vw;
    width: 80vw;
    background-position: center center;
    background-size: cover;
    color: $off-white;
    text-shadow: 2px 2px 4px rgba($black, 0.6);
    text-transform: uppercase;
    border-radius: 50%;
    margin-bottom: 10vh;
    opacity: 0;
    transform: translateY(30px);
    @media screen and (min-width: $bp-sm) {
      height: 53vw;
      width: 53vw;
    }
    @media screen and (min-width: $bp-md-sm) {
      height: 37rem;
      width: 37rem;
    }
    @media screen and (min-width: $bp-md) {
      margin-bottom: 0;
    }

    &.reveal {
      opacity: 1;
      transform: translateY(0);
    }

    &--left {
      background-image: linear-gradient(rgba($black, 0.4), rgba($black, 0.4)),
        url('../../../static/img/light-cube.jpg');
      transition: all 300ms cubic-bezier(0.39, 0.575, 0.565, 1);
    }
    &--mid {
      background-image: linear-gradient(rgba($black, 0.4), rgba($black, 0.4)),
        url('../../../static/img/digi-circle.jpg');
      transition: all 300ms 100ms cubic-bezier(0.39, 0.575, 0.565, 1);
    }
    &--right {
      background-image: linear-gradient(rgba($black, 0.4), rgba($black, 0.4)),
        url('../../../static/img/blue-network.png');
      transition: all 300ms 200ms cubic-bezier(0.39, 0.575, 0.565, 1);
    }
  }

  &__item-stat {
    font-size: 4rem;
    font-weight: 700;
    letter-spacing: 1.2px;
    line-height: 170%;
    @media screen and (min-width: $bp-sm) {
      font-size: 5rem;
    }
  }

  &__item-description {
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: 1.2px;
    text-align: center;
    padding: 0 2rem;
    margin-bottom: 5vh;
    @media screen and (min-width: $bp-sm) {
      font-size: 2.4rem;
    }
    @media screen and (min-width: $bp-md) {
      font-size: 1.8rem;
    }
  }
}
