@import '../../../scss/abstractions.scss';

.table-display {
  &__table-header {
    padding: 4rem 0 0.5rem 10vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (min-width: $bp-sm) {
      padding: 4rem 2vw 0.5rem 5vw;
    }
  }

  &__header-title {
    text-transform: uppercase;
    font-weight: 700;
    color: $grey-dark;
    font-size: 1.2rem;
    width: 30%;

    @media screen and (min-width: $bp-sm) {
      width: 10%;
    }

    &.remove-at-sm {
      display: none;

      @media screen and (min-width: $bp-sm) {
        display: block;
      }
    }
  }

  &__table-body {
    display: flex;
    flex-direction: column;
  }
  &__pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 45vw;
    font-size: 1.8rem;
    font-weight: 500;
    color: $grey-dark;
    padding: 3rem 0 3rem 12vw;
    @media screen and (min-width: $bp-sm) {
      padding: 3rem 0 3rem 5vw;
      width: 30vw;
    }

    @media screen and (min-width: $bp-md-sm) {
      width: 20vw;
    }

    @media screen and (min-width: $bp-md) {
      width: 12vw;
    }

    a {
      color: $grey-dark;
    }

    span {
      backface-visibility: hidden;
      position: relative;
      z-index: 5;
      cursor: default;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background-color: $yellow;
        padding: 5px 7px;
        z-index: -1;
        border-radius: 4px;
      }
    }
  }
}
