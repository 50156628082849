@import '../../../scss/abstractions.scss';

.notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vh 5vw;

  @media screen and (min-width: $bp-md) {
    padding: 5vh 2vw 2vh 2vw;
  }

  p {
    text-align: left;
    letter-spacing: 1px;

    @media screen and (min-width: $bp-xs) {
      text-align: left;
    }

    @media screen and (min-width: $bp-sm) {
      text-align: left;
    }
  }

  & .MuiList-root {
    width: 100%;
    padding: 0 0 1vh 0;
    @media screen and (min-width: $bp-sm) {
      max-width: 50vw;
    }
    @media screen and (min-width: $bp-md-sm) {
      max-width: 30vw;
    }
    @media screen and (min-width: $bp-md) {
      max-width: 100%;
    }

    & .MuiTypography-body1 {
      font-size: 1.4rem;
      text-transform: uppercase;
      font-family: $montserrat;
      font-weight: 700;
      letter-spacing: 0.8px;
    }
  }

  & .MuiListItemIcon-root {
    margin-left: 0;
    min-width: 30px;

    &.not-connected {
      opacity: 0.2;
    }
  }

  & .MuiListItem-root {
    padding: 3rem 0;
  }

  & .MuiListItemText-root {
    color: #c4c4c4;
    max-width: 150px;
    word-wrap: break-word;
    transition: color 200ms cubic-bezier(0.39, 0.575, 0.565, 1);
    &.not-connected {
      color: rgba($grey-standard, 0.3);
    }
    &.active--email {
      color: $green;
    }
    &.active--discord {
      color: $violet;
    }
    &.active--telegram {
      color: $blue;
    }
  }

  & .switch {
    &--email {
      .MuiSwitch-colorSecondary.Mui-checked {
        color: $green;

        &:hover {
          background-color: rgba($green, 0.2);
        }
      }

      .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: $green;
      }
    }
    &--discord {
      .MuiSwitch-colorSecondary.Mui-checked {
        color: $violet;

        &:hover {
          background-color: rgba($violet, 0.2);
        }
      }

      .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: $violet;
      }
    }

    &--telegram {
      .MuiSwitch-colorSecondary.Mui-checked {
        color: $blue;

        &:hover {
          background-color: rgba($blue, 0.2);
        }
      }

      .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: $blue;
      }
    }
  }
}
