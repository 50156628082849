@import '../../../scss/abstractions.scss';

.password-form {
  @extend %dash-component-form;

  div {
    margin-bottom: 2rem;
    &:last-of-type {
      margin-bottom: 2rem;
    }
  }
  input[type='submit'] {
    margin: 2vh 0;
  }
}
